import "./PartnerLogo.css";

export function PartnerLogo() {
    return (
      <div className="PartnerLogoContainer">
      <h1>PARTNERS</h1>
      <div className="PartnerLogos">
      <a target="_blank" rel="noopener noreferrer" href="https://vogue.sg/"><img className="PartnerLogoImg" src="logo (1).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.spatial.io/"><img className="PartnerLogoImg" src="logo (7).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://decentraland.org/"><img className="PartnerLogoImg" src="logo (2).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.shavonnewong.com/"><img className="PartnerLogoImg" src="logo (6).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.thefabricant.com/"><img className="PartnerLogoImg" src="logo (9).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/terrygates/?hl=en"><img className="PartnerLogoImg" src="logo (8).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://dressx.com/"><img className="PartnerLogoImg" src="logo (4).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://thedematerialised.com/"><img className="PartnerLogoImg" src="logo (3).png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.ilonasong.com/"><img className="PartnerLogoImg1" src="logo (5).png"/></a>
      </div>
    </div>
    )
}
export default PartnerLogo;
