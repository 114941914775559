import "./Profile.css";
import "./NFTCarousel.css";
import "./QuestSystem.css";
import { useState, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import GiftModal from "../GiftModal/GiftModal";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../Web3Login/LoginSlice";
import {
  useAccount,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from "wagmi";
import CommonButton from "../CommonButton/CommonButton";
import QuizNumberBox from "../QuizNumberBox/QuizNumberBox";
import BannerWithSubtitle from "../BannerWithSubtitle/BannerWithSubtitle";
import ProfileForm from "../ProfileForm/ProfileForm";
import { useAppContext } from "../../contexts/AppContext";
import { Tooltip } from '@mui/material';
import api from "../API/api";

const sampleQuests = [
  {
    value: 1,
    isCompleted: true,
  },
  {
    value: 2,
    isCompleted: false,
  },
  {
    value: 3,
    isCompleted: false,
  },
  {
    value: 4,
    isCompleted: false,
  },
  {
    value: 5,
    isCompleted: false,
  },
  {
    value: 6,
    isCompleted: false,
  },
  {
    value: 7,
    isCompleted: false,
  }
]

export function Profile(props) {
  const { address, connector, isConnected } = useAccount();
  const { data: ensAvatar } = useEnsAvatar({ address });
  const { data: ensName } = useEnsName({ address });
  const dispatch = useDispatch();
  const LoginState = useSelector((state) => state.loginstate.value);
  const { quests, isProfileFormOpen, setProfileFormOpen, name, discord, twitter, userPoints } = useAppContext();
  const [todayQuest, setTodayQuest] = useState(null);
  const [profileFormFields, setProfileFormFields] = useState({ titleText: "", dialogText: "", labelText: "", keyValue: "" })
  const [questType, setQuestType] = useState(null);
  const [socialLink, setSocialLink] = useState(null);
  const [payload, setPayload] = useState(null);

  const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WEBSITE_ROOT_URL_DEV : process.env.REACT_APP_WEBSITE_ROOT_URL_PROD
  const handleLogout = () => {
    localStorage.setItem("siweLoggedIn", false);
    dispatch(logout());
    // useDisconnect;
    window.location = baseURL
  }

  const handleProfileFormClick = ({ type }) => {
    setProfileFormOpen(!isProfileFormOpen)
    if (type == "name") {
      setProfileFormFields(prevDict => ({
        ...prevDict,
        titleText: "Name",
        dialogText: "Name",
        labelText: "New Name",
        keyValue: "name"
      }))
    } else if (type == "discord") {
      setProfileFormFields(prevDict => ({
        ...prevDict,
        titleText: "Discord",
        dialogText: "Discord",
        labelText: "e.g. user#123",
        keyValue: "discord_account"
      }))
    } else if (type == "twitter") {
      setProfileFormFields(prevDict => ({
        ...prevDict,
        titleText: "Twitter",
        dialogText: "Twitter",
        labelText: "e.g. @user",
        keyValue: "twitter_account"
      }))
    }
    console.log(profileFormFields)
  }


  // console.log(props.profileData)

  function NFTCarousel(props) {

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: props.tokenid.length < 5 ? props.tokenid.length : 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: props.tokenid.length < 5 ? props.tokenid.length : 5,
      },
      desktop2: {
        breakpoint: { max: 1600, min: 1024 },
        items: props.tokenid.length < 4 ? props.tokenid.length : 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: props.tokenid.length < 3 ? props.tokenid.length : 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: props.tokenid.length < 2 ? props.tokenid.length : 2,
      }
    };

    return (
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        infinite={false}
        containerClass="carousel-container"
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {props.tokenid.map((id, i) => (
          <Item key={i} id={id} />
        ))}
      </Carousel>
    );
  }

  function Item(props) {
    let nftURL = "https://opensea.io/assets/ethereum/0xfddc8e98532d41732d09bbc291bcd0698b21a4eb/" + String(props.id)
    return (
      <div className="carouselItem">
        <h1>
          LOVECHAIN #{props.id} <br />{props.id % 2 === 0 ? "(DREAM)" : "(DESIRE)"}
        </h1>
        <Tooltip title="Check your NFT out on Opensea!">
          <a href={nftURL} target="_blank" rel="noopener noreferrer"><img
            className="NFTImg"
            src={props.id % 2 === 0 ? "NFT1.png" : "NFT2.png"}
          /></a>
        </Tooltip>
        <br />
      </div>
    );
  }

  const startQuest = () => {
    console.log(questType)
    const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WEBSITE_ROOT_URL_DEV : process.env.REACT_APP_WEBSITE_ROOT_URL_PROD
    if (questType == "Quiz") {
      window.location = `${baseURL}/quest`;
    } else if (questType == "Tweet") {
      window.open(socialLink, "_blank")
      api
        .completeQuest(payload)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        }).finally(() => {
        });
      console.log("Tweet")
    } else if (questType == "Discord") {
      window.open(socialLink, "_blank")
      api
        .completeQuest(payload)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        }).finally(() => {
        });
      console.log("Tweet")
    } else if (questType == "Spatial") {
      window.open(socialLink, "_blank")
      api
        .completeQuest(payload)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        }).finally(() => {
        });
      console.log("Spatial")
    }
  }

  const questResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
      slidesToSlide: 7 
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      slidesToSlide: 7
    },
    desktop2: {
      breakpoint: { max: 1600, min: 1024 },
      items: 7,
      slidesToSlide: 7
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 7,
      slidesToSlide: 7
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 7,
      slidesToSlide: 7
    }
  };

  const startIndex = 0; // Set the index of the starting slide

  const reorderedQuests = [...quests.slice(startIndex), ...quests.slice(0, startIndex)];

  useEffect(() => {
    api
      .dailyQuest()
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data;
          setTodayQuest(data.question);
          setQuestType(data.quest_type)
          setSocialLink(data.article);
          setPayload({
            quest_name: data.quest_name,
            quest_type: data.quest_type,
          })
        }
      })
      .catch((error) => {
        if (error) {
          console.log('logged out');
        }
      })
      .finally(() => {
      });
  }, [])

  return (
    <div className="ProfileContainer">
      <div className="ProfileContainerHeader">
        <div className="ProfileContainerHeaderLeft" />
        <div className="ProfileContainerHeaderMiddle">
          <h1>PROFILE</h1>
        </div>
        <div className="LogoutButtonContainer">
          <div className="LogoutButton" onClick={handleLogout}>
            LOGOUT
          </div>
        </div>
      </div>
      <div className="ProfileContainerBody">
        <div className="ProfileContainerBodyLeft">
          <div className="ProfileInformationContainer">
            <div className="ProfilePicture">
              <img src="vogue_8sian.jpeg" />
            </div>
            <div className="ProfileInformation">
              <Tooltip title="Change your name!">
                <div className="ProfileInformationTop" onClick={() => handleProfileFormClick({ type: "name" })}>
                  <h1>{name ? name : ensName ? ensName : "Change Name"}</h1>
                  <p>{address?.substring(0, 8) + "..." + address?.substring(37, 42)}</p>
                </div>
              </Tooltip>
              <div className="ProfileInformationBtm">
                <h1>Contribution</h1>
                <p>&nbsp;{userPoints} Points</p>
              </div>
            </div>
          </div>
          <div className="ProfileButtonContainer">
            <GiftModal />
            <Tooltip title={discord ? "Connected with " + discord : "Update Discord Information"}>
              <button className="ProfileButton" onClick={() => handleProfileFormClick({ type: "discord" })}>{discord ? "DISCORD CONNECTED" : "CONNECT DISCORD"}</button>
            </Tooltip>
            <Tooltip title={twitter ? "Connected with " + twitter : "Update Twitter Information"}>
              <button className="ProfileButton" onClick={() => handleProfileFormClick({ type: "twitter" })}>{twitter ? "TWITTER CONNECTED" : "CONNECT TWITTER"}</button>
            </Tooltip>
            <ProfileForm titleText={profileFormFields.titleText} dialogText={profileFormFields.dialogText} labelText={profileFormFields.labelText} keyValue={profileFormFields.keyValue} />
          </div>
        </div>
        <div className="ProfileContainerBodyRight">
          <div className="QuestSystemContainer">
            {/*<h1>DAILY QUESTS SYSTEM COMING<br /> ON THE 15TH OF FEB!</h1>*/}
            <h1>DAILY QUESTS COMPLETED FOR THE WEEK</h1>
            <br />
            <div className="QuestCarousel">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={questResponsive}
                infinite={true}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={200}
                minimumTouchDrag={10}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop", "desktop2", "superLargeDesktop"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {reorderedQuests?.map(({ value, isCompleted }) =>
                  <QuizNumberBox number={value} isActive={isCompleted} />
                )}
              </Carousel>
            </div>
            <br />
            {
              todayQuest && <BannerWithSubtitle title={todayQuest} />
            }
            <CommonButton btnText="START QUEST" onClick={startQuest} />
          </div>
        </div>
      </div>
      <div className="ProfileContainerFooter">
        <div className="ProfileNFTCarousel">
          <h1>
            MY NFTS({props.tokenIDs?.length})
          </h1>
          <NFTCarousel tokenid={props.tokenIDs} />
        </div>
      </div>
    </div>
  );
}
export default Profile;

// trigger
