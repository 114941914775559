import "./PreRegButton.css";
import React from "react";
import { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import api from "../API/api";


export function PreRegButton() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  console.log("preregmodal", open)

  function handleOpen() {
    setOpen(true);
    console.log(open)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubscribe = () => {
    setOpen(false);
    api
    .prereg(JSON.stringify({ email: email }))
    .then((response) => {
      if (response.status === 200) {
        console.log(response)     
      } 
    })
    .catch((error) => {
      console.log(error);
    });
  }
  

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    console.log(email)
  }



  return (
    <div className="PreRegButtonContainer">
      <button className="PreRegButton" onClick={handleOpen}>
        <h1>REGISTER HERE</h1>
      </button>
      <div>
      <Dialog open={open} handleClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email} 
            onChange={handleEmailChange} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubscribe}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
  );
}
export default PreRegButton;
