import "./CTAButton.css";

export function CTAButton() {
  return (
    <div className="CTAButtonContainer">
      <a style={{textDecoration: "none"}}target="_blank" rel="noopener noreferrer" href="https://www.spatial.io/s/Fashions-New-World-62fabe0cabce1e00010f6f9a"><div className="CTAButton">
        <h1>ENTER FASHION'S NEW&nbsp;WORLD</h1>
      </div>
      </a>
    </div>
  );
}
export default CTAButton;
