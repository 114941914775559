import LoginModal from "../LoginModal/LoginModal";
import "./Timeline.css";

export function Timeline() {
  return (
    <div className="TimelineContainer">
      <h1>HOW DOES IT WORK?</h1>
      <div className="TimelineLine" />
      <div className="TimelineBodyContainer">
        <div className="TimeLineRow">
          <div className="TimeLineRowLeft"></div>
          <div className="TimelineRowCenter">
            <span class="dot1" style={{ backgroundColor: "#F4ABF0" }}></span>
          </div>
          <div className="TimeLineRowRight">
            <a target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} href="https://www.coindesk.com/learn/how-to-set-up-a-metamask-wallet/"><p>CREATE A WEB3 WALLET</p></a>
          </div>
        </div>
        <div className="TimeLineRow">
          <div className="TimeLineRowLeft">
            <p>MINT LOVE CHAIN NFT</p>
          </div>
          <div className="TimelineRowCenter">
            <span class="dot2" style={{ backgroundColor: "#F4ABF0" }}></span>
          </div>
          <div className="TimeLineRowRight"></div>
        </div>
        <div className="TimeLineRow">
          <div className="TimeLineRowLeft"></div>
          <div className="TimelineRowCenter">
            <span class="dot2" style={{ backgroundColor: "#F4ABF0" }}></span>
          </div>
          <div className="TimeLineRowRight">
            <p>GIFT LOVE CHAIN NFT</p>
          </div>
        </div>
        <div className="TimeLineRow" id="CampaignRow">
          <div className="TimeLineRowLeft">
            <p>COMMUNITY CAMPAIGN</p>
          </div>
          <div className="TimeLineRowCenter" id="TopDot">
            <span class="dot1" style={{ backgroundColor: "#F4ABF0" }}></span>
          </div>
          <div className="TimeLineRowRight">
            <div className="TimelinePointForm">
              <h1>
                Love Chain NFT holders will participate in Community Quests to
                fill up the Love Meter via points.
              </h1>
              <h1>Points can be accrued in the following ways:</h1>
              <ul>
                <li>Purchasing Love Chain NFT</li>
                <li>Gifting Love Chain NFT </li>
                <li>
                  Participating in the Community Treasure Hunt hosted on Spatial
                  / Decentraland
                </li>
                <li>Completion of Love Meter Quests</li>
              </ul>
            </div>
            <div className="CompleteButtonContainer2">
              {/* <LoginModal buttontype="timelineLogin"/> */}
            </div>
          </div>
        </div>
        <div className="TimeLineRow" id="RewardsRow">
          <div className="TimeLineRowLeft">
            <img src="NFTReward.gif" id="RewardsRowImg" />
          </div>
          <div className="TimelineRowCenter">
            <span class="dot1" style={{ backgroundColor: "#F89DA1" }}></span>
          </div>
          <div className="TimeLineRowRight" id="RightCenter">
            <p>REWARDS</p>
          </div>
        </div>
        <div className="TimeLineRow" id="Tier1Row">
          <div className="TimeLineRowLeft">
            <div className="TimelineCardContainer">
              <h1>Tier 1</h1>
              <h2>
                All Love Chain NFT holders will unlock an exclusive 8SIAN 3D
                Valentine’s bespoke NFT
              </h2>
            </div>
          </div>
          <div className="TimelineRowCenter">
            <span class="dot2" style={{ backgroundColor: "#F9957A" }}></span>
          </div>
          <div className="TimeLineRowRight">
            <img src="TimelineImg2.png" />
          </div>
        </div>
        <div className="TimeLineRow" id="Tier2Row">
          <div className="TimeLineRowLeft">
            <img src="TimelineImg3.png" />
          </div>
          <div className="TimelineRowCenter">
            <span class="dot2" style={{ backgroundColor: "#F9957A" }}></span>
          </div>
          <div className="TimeLineRowRight">
            <div className="TimelineCardContainer" id="TextAlignLeft">
              <h1>Tier 2</h1>
              <h2>
                All Love Chain NFT holders will unlock a digital wearable by
                DRESSX
              </h2>
            </div>
          </div>
        </div>
        <div className="TimeLineRow" id="Tier3Row">
          <div className="TimeLineRowLeft">
            <div className="TimelineCardContainer">
              <h1>Tier 3</h1>
              <h2>
                All Love Chain NFT holders will be eligible to unlock various
                prizes by official partners; Vogue, Terry Gates, Ilona Song &
                more!
              </h2>
            </div>
          </div>
          <div className="TimelineRowCenter">
            <span class="dot2" style={{ backgroundColor: "#F9957A" }}></span>
          </div>
          <div className="TimeLineRowRight"></div>
        </div>
        <div className="TimeLineRow" id="Final">
          <div className="TimeLineRowLeft">
          </div>
          <div className="TimelineRowCenter">
            <span class="dot1" style={{ backgroundColor: "#FC893A" }}></span>
          </div>
          <div className="TimeLineRowRight"></div>
        </div>
      </div>
    </div>
  );
}
export default Timeline;
