import React, { useState, useMemo, useEffect, useCallback } from 'react';
import './Quiz.css';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import ChevronLeft from "../logos/ChevronLeft";
import CommonButton from "../CommonButton/CommonButton";
import api from "../API/api";
import Loading from "../Loading/Loading";
import { useAccount } from "wagmi";
import { useAppContext } from "../../contexts/AppContext";
import Congratulations from "../Congratulations/Congratulations";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 786,
            lg: 1000,
            xl: 1200,
        },
    },
});

const Quiz = ({ loggedin }) => {
    const [selectedAnsIdx, setSelectedAnsIdx] = useState(null);
    const [selectedAns, setSelectedAns] = useState(null);
    const { address } = useAccount();
    const { walletAddress } = useAppContext();
    const [quest, setQuest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiErr, setApiErr] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitErr, setSubmitErr] = useState(null);
    const [tryAgain, setTryAgain] = useState(false);
    const isAnsEmpty = useMemo(() => selectedAnsIdx === null, [selectedAnsIdx]);
    const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WEBSITE_ROOT_URL_DEV : process.env.REACT_APP_WEBSITE_ROOT_URL_PROD

    const goBack = () => {
        window.location = `${baseURL}/profile`;
    }

    useEffect(() => {
        setLoading(true);
        setApiErr(null);
        api
            .dailyQuest()
            .then((response) => {
                if (response.status === 200) {
                    const data = response?.data;
                    setQuest(data);
                }
                setLoading(false);
            })
            .catch((error) => {
                if (error) {
                    console.log('logged out');
                    setApiErr(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const renderAnswers = useMemo(() => quest?.answers?.map(({ value, label }, index) =>
        <li key={index} role="button" onClick={() => {
            setSelectedAnsIdx(index)
            setSelectedAns(label);
        }}
            className={index === selectedAnsIdx ? "AnswerWrapper ActiveWrapper" : "AnswerWrapper"}>
            <div className="AnswerContainer">
                <div className="AnswerLabel">
                    <h1>{label}</h1>
                </div>
                <div className="AnswerValue">
                    <p>{value}</p>
                </div>
            </div>
        </li>
    ), [quest, selectedAnsIdx]);


    const handleSubmit = useCallback(() => {
        const payload = {
            walletAddress: walletAddress ? walletAddress : address,
            answer: selectedAns,
            quest_name: quest?.quest_name,
            quest_type: quest?.quest_type,
        }
        api
            .completeQuest(payload)
            .then((response) => {
                const data = response?.data;
                if (response.status === 200 && data === 'Correct answer!') {
                    setSubmitSuccess(true);
                } else if (response.status === 200 && data === 'Wrong answer!') {
                    setSubmitErr("Sorry but that was the incorrect answer! Please try again");
                    setTryAgain(true);
                } else if (response.status === 200 && data === 'You have already completed the quest!') {
                    setSubmitErr("Sorry! You have already completed the quest before! Only 1 correct submission per quest is allowed.");
                }
                setLoading(false);
            })
            .catch((error) => {
                if (error) {
                    console.log(error);
                    setSubmitErr("Something went wrong. Please try again later.");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [quest, address, walletAddress, selectedAns]);

    if (!loggedin) return null;
    if (loading) return <Loading />

    if (!loading && quest && !submitSuccess && !submitErr) {
        return (
            <ThemeProvider theme={theme}>
                <div className="Quiz">
                    <div className="QuizContent">
                        <div className="QuizHeaderContainer">
                            <IconButton onClick={goBack}>
                                <ChevronLeft />
                            </IconButton>
                            <div className="QuizHeader">
                                <h1>QUIZ</h1>
                                <a href={quest?.article} target="_blank" rel="noopener noreferrer"><p>Check out <i>Vogue's</i> <u>Article</u> to&nbsp;answer&nbsp;the&nbsp;question!</p></a>
                            </div>
                        </div>
                        <h1 className="QuestionHeader">{quest?.quest_name}</h1>
                        <p className="QuizDescription">{quest?.question}</p>
                        <div className="AnswerSection">
                            <ul className="AnswerList">
                                {renderAnswers}
                            </ul>
                            <div className="ButtonContainer">
                                <CommonButton
                                    className="NextButton"
                                    onClick={handleSubmit}
                                    btnId={isAnsEmpty ? "NextButtonGreyed" : ""}
                                    disabled={isAnsEmpty}
                                    btnText="SUBMIT" />
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
    if (!loading && submitSuccess) {
        return (
            <ThemeProvider theme={theme}>
                <div className="Quiz">
                    <Congratulations title="CONGRATULATIONS ON COMPLETING THE QUIZ!"
                        imgSrc="lovepile.png"
                        type={2}
                        subtitle={"That is the correct answer! You have completed today’s Community Quest and contributed 20 points to the Love Meter!"}
                    />
                    <div className="SuccessBack" role="button" onClick={goBack}>
                        Back to profile
                    </div>
                </div>
            </ThemeProvider>
        )
    }

    if (!loading && submitErr) {
        return (
            <ThemeProvider theme={theme}>
                <div className="Quiz">
                    <div className="QuizError">
                        <Congratulations title="OH NO!"
                            subtitle={submitErr}
                            imgSrc="heartbreak.png"
                            toShare={false}
                        />
                    </div>
                    <div className="ButtonWrapper">
                        {
                            tryAgain ? (<CommonButton
                                className="NextButton"
                                onClick={() => { setSubmitErr(false) }}
                                btnText="Try Again" />
                            ) : <CommonButton
                                className="NextButton"
                                onClick={goBack}
                                btnText="Back to profile" />
                        }
                    </div>
                </div>
            </ThemeProvider>
        )
    }
    return null;
};

export default Quiz;
