import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [openModal, setOpenModal] =
    useState('this is default context value');
  const [quests, setQuests] = useState([]);
  const [walletAddress, setWalletAddress] = useState(null);
  const [name, setName] = useState(null)
  const [discord, setDiscord] = useState(null)
  const [twitter, setTwitter] = useState(null)
  const [userUUID, setUserUUID] = useState(null)
  const [userPoints, setUserPoints] = useState(0)
  const [isProfileFormOpen, setProfileFormOpen] = useState(false)

  useEffect(() => {
  }, []);

  const value = {
    openModal,
    setOpenModal,
    quests,
    setQuests,
    walletAddress,
    setWalletAddress,
    name,
    setName,
    discord,
    setDiscord,
    twitter,
    setTwitter,
    userUUID,
    setUserUUID,
    isProfileFormOpen,
    setProfileFormOpen,
    userPoints,
    setUserPoints
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
