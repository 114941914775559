import { useState } from "react";
import LoginModal from "../LoginModal/LoginModal";
import "./LoveMeter.css";

export function LoveMeter() {
  const [LoveMeterPoints, setLoveMeterPoints] = useState(250000);
  const currentDateTime = new Date().toLocaleString() + "";
  return (
    <div className="LoveMeterContainer">
      <h1>LOVE METER</h1>
      <div className="LoveMeterTrackerContainer">
        <div className="LoveMeterLeft">
          <img src="LoveMeter0.png" />
        </div>
        <div className="LoveMeterRight">
          <div className="TierSection">
            <div className="TierBoxBorderFill">
              <div className="TierBoxFill">TIER 3</div></div>
            <p>Prizes from <i>VOGUE</i>&nbsp;SINGAPORE, Terry Gates, Ilona Song and more!</p>
          </div>
          <div className="TierSection">
            <div className="TierBoxBorderFill">
              <div className="TierBoxFill">TIER 2</div></div>
            <p>DRESSX Exclusive Wearables</p>
          </div>
          <div className="TierSection">
            <div className="TierBoxBorderFill">
              <div className="TierBoxFill">TIER 1</div></div>
            <p>8SIAN 3D Valentine's Bespoke NFT</p>
          </div>
        </div>
      </div>
      <div className="LoveMeterPointsContainer">
        <p>Current Accumulated Points</p>
        <h1>{LoveMeterPoints.toLocaleString()}</h1>
        <p>Last updated: {currentDateTime}</p>
      </div>
      <div className="LoveMeterHeartsContainer">
        <img src="TIER 1 HEART.png" />
        <img src="TIER 2 HEART.png" />
        <img src="TIER 3 HEART.png" />
      </div>
      {<div className="LoveMeterCTA">
        <p>Completed your Love Meter Quests Today?</p>
        <div className="CompleteButtonContainer">
          <div className="CompleteButton">
            <LoginModal buttontype="lovemeterLogin"/>
          </div>
        </div>
      </div>}
      <div className="PointSystemOverallContainer">
        <h1>How to collect points</h1>
        <div className="PointSystemInfoContainer">
          <div className="PointSystemInfoBorder">
            <div className="PointSystemInfo">
              <div className="PointSystemInfoLeft">
                <h1>20</h1>
                <p>Points</p>
              </div>
              <div className="PointSystemInfoRight">
                <p>For every daily quest completed</p>
              </div>
            </div>
          </div>
        </div>
        <div className="PointSystemInfoContainer">
          <div className="PointSystemInfoBorder">
            <div className="PointSystemInfo">
              <div className="PointSystemInfoLeft">
                <h1>50</h1>
                <p>Points</p>
              </div>
              <div className="PointSystemInfoRight">
                <p>Participating in the Community Treasure Hunt in Fashion's New World</p>
              </div>
            </div>
          </div>
        </div>
        <div className="PointSystemInfoContainer">
          <div className="PointSystemInfoBorder">
            <div className="PointSystemInfo">
              <div className="PointSystemInfoLeft">
                <h1>80</h1>
                <p>Points</p>
              </div>
              <div className="PointSystemInfoRight">
                <p>For every gifting of an NFT</p>
              </div>
            </div>
          </div>
        </div>
        <div className="PointSystemInfoContainer">
          <div className="PointSystemInfoBorder">
            <div className="PointSystemInfo">
              <div className="PointSystemInfoLeft">
                <h1>100</h1>
                <p>Points</p>
              </div>
              <div className="PointSystemInfoRight">
                <p>For every Mint</p>
              </div>
            </div>
          </div>
        </div>
        <div className="PointSystemInfoContainer" id="PointSystemInfoContainerSpecial">
          <div className="PointSystemInfoBorder" id="PointSystemInfoBorderBig">
            <div className="PointSystemInfo" id="PointSystemInfoBig">
              <h1>160 BONUS POINTS</h1>
              <p>For consecutive completion of 7 quests in a row</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoveMeter;
