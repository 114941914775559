import { useState } from 'react';
import LoginModal from '../LoginModal/LoginModal';
import LoveListMintModal from '../LoveListMintModal/LoveListMintModal';
import MintModal from '../MintModal/MintModal';
import './Header.css'

export function Header({ noWeb3 }) {

  const [dropdown, setDropdown] = useState(false)
  const handleDropdownClick = () => {
    setDropdown(!dropdown)
  }

  return (
    <div className="HeaderContainer">
      <div className="NavbarBlankContainer">

      </div>
      <div className="NavbarLogoContainer">
        <div className="LeftLogo">
          <a target="_blank" rel="noopener noreferrer" href="https://8sian.io/vogue"><img src="8sian-logo.png" /></a>
        </div>
        <div className="Divider">
          <div className="vl" />
        </div>
        <div className="RightLogo">
          <a target="_blank" rel="noopener noreferrer" href="https://8sian.io/vogue"><img src="vogue-logo.png" /></a>
        </div>
      </div>
      <div className="NavbarLoginButtons">
        <div className="NavbarLoginButtonsWrapper">
          {/* <LoveListMintModal buttontype="navbarMintButton"/> */}
          <MintModal buttontype="navbarMintButton" />
           <LoginModal buttontype="loginButton" />
        </div>
        <div className="dropdown">
          <button className="dropbtn" onClick={handleDropdownClick}>
            <img src="dropdown.png"/>
          </button>
          <div className="dropdown-content" style={{display: dropdown? "block":"none"}}>
            <MintModal buttontype="navbarMintButton" />
             <LoginModal buttontype="loginButton" noWeb3={noWeb3} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
