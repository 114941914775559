import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQ.css";

const FAQItems = [
  {
    group: "CAMPAIGN FAQS",
    questions: [
      {
        question: (
          <b>
            What is the 8SIAN x VOGUE Singapore Love Chain NFT and what can I
            get by minting?
          </b>
        ),
        answer: (
          <>
            <p>
              Introducing our 8SIAN x <i>VOGUE</i> Singapore Love Chain NFT, the
              ultimate Valentine’s Day gift - featuring romantic and captivating
              pieces that are sure to make your heart flutter. Each piece
              captures the essence of love and passion. Whether you're looking
              to surprise a loved one, send a gift to your secret web3 crush,
              gift a web2 native their first NFT or simply treat yourself, our
              collection has something for everyone. Indulge in the romance of
              art and the joy of gifting this Valentine's Day with 8SIAN x{" "}
              <i>VOGUE</i> Singapore Love Chain NFT. Treat your loved one to a
              piece of art that they will treasure forever in the blockchain!
            </p>
            <p>
              By minting the 8SIAN x VOGUE Singapore Love Chain NFT, you will be
              first receiving a pair of heart-shaped Love Chain NFT. The Love
              Chain NFT provides you with the following utilities:
            </p>
            <ul>
              <li>Receive a pair of Love Chain NFTs.</li>
              <li>
                Gift a Love Chain NFT to a friend / loved one, immortalizing
                their gift forever in the blockchain.
              </li>
              <li>Access to exclusive Community Campaigns.</li>
              <li>
                Complete Community Campaigns to collect points for the Love
                Meter which unlocks various tiered prizes.
              </li>
            </ul>
          </>
        ),
      },
      {
        question: <b>What is the Love Meter and how does it work?</b>,
        answer: (
          <>
            <p>
              Love Meter measures the progress of the community goals to be
              achieved together by the Love Chain NFT holders. Complete all
              tiers to unlock every prize.
            </p>
            <p>
              As the community completes quests, the Love Meter will reflect
              this contribution and will be updated daily on the website.
            </p>
            <p>Points can be accrued in the following ways:</p>
            <ul>
              <li>
                100 Points by purchasing 8SIAN x VOGUE Singapore Love Chain NFT
              </li>
              <li>
                80 Points by gifting 8SIAN x VOGUE Singapore Love Chain NFT
              </li>
              <li>
                50 Points by participating in the Community Treasure Hunt{" "}
              </li>
              <li>
                20 Points by completion of Community Quests, bonus 160 points
                for completing 7 days in a row
              </li>
            </ul>
            <p>
              Note that completion of Community Quests are tracked on a per
              wallet basis.{" "}
            </p>
          </>
        ),
      },
      {
        question: <b>Examples of how points are calculated</b>,
        answer: (
          <>
            <p>
              Example 1: You mint 1x pair of Love Chain NFT, gift 1x Love Chain
              NFT and complete Community Quests 7 days in a row. Points = 100 +
              80 + 20x7 + 160 = 480 points Your friend who received the NFT
              completes Community Quests 7 days in a row Points = 20x7 + 160 =
              300 points Total points = 780 points
            </p>
            <p>
              Example 2: You mint 1x pair of Love Chain NFT and complete
              Community Quests 7 days in a row. Total points = 100 + 20x7 + 160
              = 400 points (No multiplier on community quest points for having
              multiple NFTs)
            </p>
            <p>
              Example 3: You mint 3x pairs of Love Chain NFT, gift 3x Love Chain
              NFTs and complete Community Quests 7 days in a row. Points = 3x100
              + 3x80 + 20x7 + 160 = 840 points Your 3 friends who received the
              NFT completes Community Quests 7 days in a row Points = 3x (20x7 +
              160) = 900 Total points = 1,740 points
            </p>
          </>
        ),
      },
      {
        question: <b>How long does the campaign last for?</b>,
        answer: (
          <>
            <p>
              The campaign starts with the Lovelist mint on on 13th Feb and
              lasts until 15th March 2023 at 10pm SGT / 2pm UTC
            </p>
          </>
        ),
      },
      {
        question: <b>What happens once the campaign ends?</b>,
        answer: (
          <>
            <p>
              If not sold out, minting will end and remaining supply will be
              burned. Total points will be tallied and the rewards process will
              start.
            </p>
          </>
        ),
      },
    ],
  },
  {
    group: "PURCHASING FAQS",
    questions: [
      {
        question: <b>How do I obtain an NFT wallet?</b>,
        answer: (
          <>
            <p>
              There are many NFT wallets. For this campaign, a Metamask wallet
              is preferred. If you do not have a Metamask wallet, please click
              the following link for your wallet setup:
            </p>
            <a href="https://www.coindesk.com/learn/how-to-set-up-a-metamask-wallet/">
              https://www.coindesk.com/learn/how-to-set-up-a-metamask-wallet/
            </a>
          </>
        ),
      },
      {
        question: (
          <b>
            Where do I purchase this 8SIAN x VOGUE Singapore Love Chain NFT?
          </b>
        ),
        answer: (
          <>
            <p>
              8SIAN x <i>VOGUE</i> Singapore Love Chain NFT is available at{" "}
              <a href="8sian.io/vogue">8sian.io/vogue</a>. Before connecting
              your wallet and making a purchase, kindly double-check the URL.
              Money loss could result from disregarding this directive.
            </p>
          </>
        ),
      },
      {
        question: (
          <b>What is the price of an 8SIAN x VOGUE Singapore Love Chain NFT?</b>
        ),
        answer: (
          <>
            <p>
              The price of an 8SIAN x <i>VOGUE</i> Singapore Love Chain NFT is
              0.022 ETH (excluding the Ethereum network fee for the
              transaction).
            </p>
          </>
        ),
      },
      {
        question: <b>When does the sale start?</b>,
        answer: (
          <>
            <p>
              The Public sale for the 8SIAN x <i>VOGUE</i> Singapore Love Chain
              NFT will start on 14 Feb 2023 at 10pm SGT/ 2pm UTC for Love List
              and Private mint starts on 13 Feb 2023 at 10pm SGT/ 2pm UTC
            </p>
          </>
        ),
      },
      {
        question: <b>When is the last day to mint?</b>,
        answer: (
          <>
            <p>
              The last day to mint 8SIAN x <i>VOGUE</i> Singapore Love Chain NFT
              will end on 15 Mar 2023 at 10pm SGT/ 2pm UTC
            </p>
          </>
        ),
      },
      {
        question: <b>What is the supply?</b>,
        answer: (
          <>
            <p>There is a total of 3,000 Love Chain NFTs.</p>
          </>
        ),
      },
      {
        question: (
          <b>
            What happens if I don't want to give my Love Chain NFT to a friend?
          </b>
        ),
        answer: (
          <>
            <p>
              You can opt not to gift 1x Love Chain NFT but no points will be
              accumulated towards the Love Meter. Refer to calculation examples.
            </p>
          </>
        ),
      },
      {
        question: <b>Can I mint more than 1x pair of Love Chain NFTs?</b>,
        answer: (
          <>
            <p>
              There is a limit of 4x pairs of Love Chain NFT per wallet on the Lovelist. Once the public mint has launched, there will be no purchase limit. Each mint and gift of the Love Chain NFT will contribute to the Love Meter. However, points for the community Quests are limited on a per wallet basis.
            </p>
          </>
        ),
      },
      {
        question: <b>How will the prizes be determined if I own more than 1 Love Chain NFT?</b>,
        answer: (
          <>
            <p>
              Each Love Chain NFT will stand a chance to win prizes. The more NFTs you own, the more prizes you win (if prizes are per NFT basis) and the higher the chances to win (if raffle of prizes is applicable)
            </p>
          </>
        ),
      },
    ],
  },
];

export default function FAQAccordion() {
  return (
    <div className="FAQContainer">
      <h1>Frequently Asked Questions (FAQ)</h1>
      {FAQItems.map((questionSet) => (
        <div className="FAQGroup">
          <h2>{questionSet.group}</h2>
          {questionSet.questions.map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="FAQQuestion">{item.question}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="FAQAnswer">{item.answer}</div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ))}
    </div>
  );
}
