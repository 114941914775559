import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from "jwt-decode";

export const ProfileSlice = createSlice({
  name: 'ProfileInfo',
  initialState: {
    value: localStorage.getItem('siweLoggedIn') || '',
    tokenids: [],
    access: JSON.parse(localStorage.getItem("authTokens")) || ''
  },
  reducers: {
    store: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.tokenids = action.payload
    },
    access: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.access = ''
      state.access = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { store } = ProfileSlice.actions
export const { access } = ProfileSlice.actions
// export const { logout } = ProfileSlice.actions

export default ProfileSlice.reducer