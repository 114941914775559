import "./PostLogin.css";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Profile from "../Profile/Profile";
import api from "../API/api";
import { useAccount } from 'wagmi'
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux'
import { store, access } from '../Profile/ProfileSlice'
import { logout } from "../Web3Login/LoginSlice";
import {useAppContext} from "../../contexts/AppContext";

export function PostLogin() {
    const { address } = useAccount()
    const [APILoading, setAPILoading] = useState(false);
    const {setQuests, setWalletAddress, setName, setDiscord, setTwitter, setUserUUID, setUserPoints} = useAppContext();
    //   const [profileData, setProfileData] = useState();

    const dispatch = useDispatch()
    const tokenIDs = useSelector((state) => state.profile.tokenids);
    const authTokens = useSelector((state) => state.profile.access);
    const config = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${authTokens?.access}`
        }
    }

    const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WEBSITE_ROOT_URL_DEV : process.env.REACT_APP_WEBSITE_ROOT_URL_PROD
    const handleLogout = () => {
      localStorage.setItem("siweLoggedIn", false);
      dispatch(logout());
      window.location = baseURL
    }

    useEffect(() => {
        setAPILoading(true);
        api
            .profile(config)
            .then((response) => {
                if (response.status === 200) {
                    const data = response?.data;
                    dispatch(store(data?.tokenids));
                    setQuests(data?.quest_completion);
                    setUserPoints(data?.points)
                    setWalletAddress(data?.walletAddress);
                    setName(data?.name);
                    setDiscord(data?.discord_account)
                    setTwitter(data?.twitter_account)
                    setUserUUID(data?.tokengatedUser_uuid)
                }
            })
            .catch((error) => {
                if(error) {
                    console.log('logged out');
                    handleLogout()
                }
            })
            .finally(() => {
                setAPILoading(false);
            });
    }, []);

    function componentSelector() {
        if (APILoading) {
            return (<div className="LoadingAPIContainer"><CircularProgress sx={{ color: "linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%)" }} /></div>)
        } else {
            return (
                <>
                    <Profile tokenIDs={tokenIDs} />
                </>
            )
        }
    }

    return (
        <div className="PostLoginContainer">
            {componentSelector()}
        </div>
    );
}
export default PostLogin;
