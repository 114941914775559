import React from 'react';

const ChevronLeft = () => {
    return (
        <svg width="19" height="32" viewBox="0 0 19 32" fill="none">
            <path d="M15.5 31.5001L0.875 16.8751C0.736111 16.7362 0.638889 16.5973 0.583333 16.4584C0.527778 16.3195 0.5 16.1667 0.5 16.0001C0.5 15.8334 0.527778 15.6806 0.583333 15.5417C0.638889 15.4029 0.736111 15.264 0.875 15.1251L15.5 0.500082C15.8056 0.194526 16.1944 0.041748 16.6667 0.041748C17.1389 0.041748 17.5278 0.194526 17.8333 0.500082C18.1667 0.833415 18.3333 1.22925 18.3333 1.68758C18.3333 2.14591 18.1667 2.54175 17.8333 2.87508L4.70833 16.0001L17.8333 29.1251C18.1667 29.4584 18.3264 29.8542 18.3125 30.3126C18.2986 30.7709 18.1389 31.1529 17.8333 31.4584C17.5 31.7917 17.1042 31.9584 16.6458 31.9584C16.1875 31.9584 15.8056 31.8056 15.5 31.5001Z" fill="black"/>
        </svg>

    );
};

export default ChevronLeft;
