import "./EventDuration.css"

export function EventDuration() {

    return (
        <div className="EventDuration">
            <p>Prizes will be disbursed by the end of March 2023</p>
            <p>Event duration starts from 15th February 2023 and lasts until 15th March 2023</p>
    </div>
    )
}
export default EventDuration;