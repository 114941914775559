import './App.css';

import Header from './components/Header/Header'
import Banner from './components/Banner/Banner'
import CustomCountdown from './components/Countdown/Countdown';
import Footer from './components/Footer/Footer';

export function App_NoWeb3( { noWeb3 }) {

  return (
    <div className='App'>
      <Header noWeb3={noWeb3} />
      <Banner/>
      <CustomCountdown/>
      <Footer/>
    </div>
  )
          }
export default App_NoWeb3;
