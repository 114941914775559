import "./Countdown.css";
import React, { useEffect } from "react";
import Countdown from "react-countdown";
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from './CountdownSlice'
import Title from "../Title/Title";
import CTAButton from "../CTAButton/CTAButton";
import LoveChain from "../LoveChain/LoveChain";
import LoveMeter from "../LoveMeter/LoveMeter";
import Timeline from "../Timeline/Timeline";
import FAQAccordion from "../FAQ/FAQ";
import PartnerLogo from "../PartnerLogo/PartnerLogo";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import EventDuration from "../EventDuration/EventDuration";
import { useAppContext } from "../../contexts/AppContext";

export function CustomCountdown() {
  const dispatch = useDispatch()
  // const { openModal } = useAppContext();
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      dispatch(toggle())
      console.log("dispatch toggle")
      return (
        <>
          <Title countdown={false} />
          <LoveChain />
          <LoveMeter />
          <Timeline />
          <EventDuration />
          <FAQAccordion />
          <PartnerLogo />
          <ScrollToTop />

        </>
      );
    } else {
      // Render a countdown
      return (
        <>
          <Title countdown={true} />
          <div className="CountdownContainer">
            <div className="CountdownClockContainer">
              <div className="CountdownColumn">
                <h1>{days}</h1>
                <p>DAYS</p>
              </div>
              <div className="Divider">
                <div className="vl2" />
              </div>
              <div className="CountdownColumn">
                <h1>{hours}</h1>
                <p>HOURS</p>
              </div>
              <div className="Divider">
                <div className="vl2" />
              </div>
              <div className="CountdownColumn">
                <h1>{minutes}</h1>
                <p>MINUTES</p>
              </div>
              <div className="Divider">
                <div className="vl2" />
              </div>
              <div className="CountdownColumn">
                <h1>{seconds}</h1>
                <p>SECONDS</p>
              </div>
            </div>
            <h1>
              LAUNCHING <br />
              <br />
              13TH&nbsp;FEBRUARY&nbsp;2023 <br className="responsivebreak" /><br className="responsivebreak" />  |&nbsp;02:00&nbsp;PM&nbsp;UTC&nbsp;| <br className="responsivebreak" /><br className="responsivebreak" /> 10:00&nbsp;PM&nbsp;SGT
            </h1>
          </div>
          <CTAButton />
        </>
      );
    }
  };

  // useEffect(() => {
  //   console.log(openModal)
  // }, [])

  return <Countdown date={Date.now()} renderer={renderer} />;
}
export default CustomCountdown;

