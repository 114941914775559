import "./Title.css";

export function Title(props) {
  if (props.countdown===true) {
  return (
    <div className="TitleContainer">
      <h1>8SIAN X <i>VOGUE</i>&nbsp;SINGAPORE LOVE&nbsp;CHAIN NFT LAUNCH COMING SOON</h1>
    </div>
  );
  } else {
    return (
      <div className="TitleContainer">
      <h1>THE SEASON OF LOVE IS HERE!</h1>
    </div>
    )
  }
}
export default Title;
