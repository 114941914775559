import PreRegButton from "../PreRegButton/PreRegButton";
import "./Banner.css";
import { useSelector } from "react-redux";
import MintModal from "../MintModal/MintModal";
// import "Banner"

export function Banner() {
  const CountdownState = useSelector((state) => state.countdown.value);
  console.log("CountdownState", CountdownState);
  if (CountdownState === false) {
    return (
      <div className="BannerContainer">
        <img className="Banner" src="PreLaunchBanner.png" />
        <div className="BannerContentContainer">
          <div className="BannerHeaderContainer">
            <h1>FROM&nbsp;BLOCKCHAIN TO LOVE&nbsp;CHAIN</h1>
          </div>
          <div className="BannerTextContainer">
            <h2 className="desktopText">
              8SIAN, in collaboration with <i>Vogue Singapore</i>, celebrates
              love in the metaverse this Valentine’s Day through a unique
              giftable Love Chain NFT set—for you and your loved one. Love Chain
              NFT holders are entitled to exclusive access to an array of
              activities and games in Fashion’s New World, <i>Vogue</i>{" "}
              Singapore’s metaverse lounge hosted on Spatial.io, with the chance
              of winning prizes upon completion.
            </h2>
            <h2 className="mobileText">
              8SIAN collaborates with Vogue Singapore to celebrate love in the
              Metaverse this Valentine's Day through a giftable Love Chain NFT
              set. Holding a Love Chain NFT provides access to activities, games
              and a chance to win unique prizes.
            </h2>
          </div>
          <div className="BannerButton">
            <PreRegButton />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="BannerContainer">
        <div className="BannerVideoContainer">
            <video className="BannerVideoLeft" autoPlay muted loop><source src="BannerVideoLeft.mp4" type="video/mp4" /></video>
            <video className="BannerVideoRight" autoPlay muted loop><source src="BannerVideoRight.mp4" type="video/mp4" /></video>
        </div>
        <div className="BannerContentContainer">
          <div className="BannerHeaderContainer">
            <h1>FROM&nbsp;BLOCKCHAIN TO LOVE&nbsp;CHAIN</h1>
          </div>
          <div className="BannerTextContainer">
            <h2 className="desktopText">
              8SIAN collaborates with Vogue Singapore to celebrate love in the Metaverse this Valentine's Day through a giftable Love Chain NFT set. Holding a Love Chain NFT provides access to activities, games and a chance to win unique prizes
            </h2>
            <h2 className="mobileText">
              8SIAN collaborates with Vogue Singapore to celebrate love in the Metaverse this Valentine's Day through a giftable Love Chain NFT set. Holding a Love Chain NFT provides access to activities, games and a chance to win unique prizes
            </h2>
          </div>
          <a className="BannerButton" href="https://www.spatial.io/s/Fashions-New-World-62fabe0cabce1e00010f6f9a" style={{textDecoration: "none"}} target="_blank" rel="noreferrer">
                        Enter Fashion's New World
                    </a>
        </div>
      </div>
    );
  }
}
export default Banner;
