import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import "./GiftModal.css";
import "./GiftForm.css";
import abi from "../../artifacts/EXV_metadata.json";
import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { ethers } from "ethers";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../API/api";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 786,
      lg: 1000,
      xl: 1200,
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '100%', sm: '600px', md: '8000px', lg: '900px', xl: ' 1200px' },
  height: { xs: '100%', sm: '600px', md: '8000px', lg: '800px', xl: '800px' },
  minHeight: "min-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: 'block',
  flexDirection: "column",
  rowGap: "10px",
  overflowY: "scroll",
  pt: 2,
  px: 4,
  pb: 3,
};

export default function GiftModal() {
  const { address } = useAccount();
  const [open, setOpen] = useState(false);
  const [selectLeft, setSelectLeft] = useState(false);
  const [selectRight, setSelectRight] = useState(false);
  const [pageState, setPageState] = useState(0);
  const contract_address = "0xfdDC8E98532d41732D09BbC291bcD0698b21A4Eb";
  const tokenIDs = useSelector((state) => state.profile.tokenids);
  console.log("redux tokenIDs in gift selector", tokenIDs)

  const handleClose = (event, reason) => {
    console.log(event, reason);
    if (reason === "backdropClick") {
      return;
    } else {
      setOpen(false);
      setSelectLeft(false);
      setSelectRight(false);
      setPageState(0);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelectLeft = (event) => {
    setSelectLeft(true);
    setSelectRight(false);
    // setSelectedTokenID(dreamNFTIDs[0]);
  };

  const handleSelectRight = (event) => {
    setSelectLeft(false);
    setSelectRight(true);
    // setSelectedTokenID(desireNFTIDs[0]);
  };

  const handleNext = () => {
    setPageState(1);
  };

  const dreamNFTIDs = tokenIDs.filter(number => {
    return number % 2 === 0;
  });

  const desireNFTIDs = tokenIDs.filter(number => {
    return number % 2 === 1;
  });

  function GiftSelector() {
    return (
      <ThemeProvider theme={theme}>
        <div className="GiftModalOverallContent">
        <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          <div className="GiftModalContent">
            <h1>LOVE CHAIN NFT GIFTING</h1>
            <div className="GiftTokenSelector">
              <div
                className="GiftTokenSelectorLeft"
                onClick={handleSelectLeft}
                id={selectLeft ? "selected" : ""}
              >
                <img src="NFT1.png" />
                <p>8SIAN X VOGUE SINGAPORE</p>
                <p>LOVE CHAIN NFT (DREAM)</p>
              </div>
              <div
                className="GiftTokenSelectorRight"
                onClick={handleSelectRight}
                id={selectRight ? "selected" : ""}
              >
                <img src="NFT2.png" />
                <p>8SIAN X VOGUE SINGAPORE</p>
                <p>LOVE CHAIN NFT (DESIRE)</p>
              </div>
            </div>
            <button
              className="NextButton"
              onClick={handleNext}
              id={selectLeft ? "" : selectRight ? "" : "NextButtonGreyed"}
              disabled={selectLeft ? false : selectRight ? false : true}
            >
              NEXT
            </button>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  function GiftForm(props) {
    const [senderName, setSenderName] = useState("");
    const [recipientName, setRecipientName] = useState("");
    const [targetAddress, setTargetAddress] = useState("");
    const [selectedTokenID, setSelectedTokenID] = useState(props.initalTokenID);
    const [recipientEmail, setRecipientEmail] = useState("");
    const [giftMessage, setGiftMessage] = useState("");
    const [giftAPILoading, setgiftAPILoading] = useState(false);

    const handleSenderNameChange = (event) => {
      setSenderName(event.target.value);
    };

    const handleRecipientNameChange = (event) => {
      setRecipientName(event.target.value);
    };

    const handleAddressChange = (event) => {
      setTargetAddress(event.target.value);
    };

    const handleTokenIDChange = (event) => {
      setSelectedTokenID(event.target.value);
    };

    const handleRecipientEmailChange = (event) => {
      setRecipientEmail(event.target.value);
    };

    const handleGiftMessageChange = (event) => {
      setGiftMessage(event.target.value);
    };

    useEffect(() => {
      if (selectLeft) {
        setSelectedTokenID(dreamNFTIDs);
      } else if (selectRight) {
        setSelectedTokenID(desireNFTIDs);
      }
    }, []);

    function useDebounce(value, delay) {
      const [debouncedValue, setDebouncedValue] = useState(value);

      useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

        return () => {
          clearTimeout(timer);
        };
      }, [value, delay]);

      return debouncedValue;
    }

    const debouncedTokenID = useDebounce(selectedTokenID);
    const { config } = usePrepareContractWrite({
      address: contract_address,
      abi: abi.output.abi,
      functionName: "transferFrom",
      args: [address, targetAddress, parseInt(debouncedTokenID) | selectedTokenID],
      enabled: Boolean(debouncedTokenID)
    });
    const { data, write } = useContractWrite(config);

    const { isLoading, isSuccess } = useWaitForTransaction({
      hash: data?.hash,
    });

    useEffect(() => {
      if (isSuccess) {
        setgiftAPILoading(true);
        api
          .gift(
            JSON.stringify({
              sender_address: address,
              recipient_address: targetAddress,
              recipient_email: recipientEmail,
              message: giftMessage,
              tokenID: debouncedTokenID,
              sender_name: senderName,
              recipient_name: recipientName
            })
          )
          .then((response) => {
            if (response.status === 200) {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setgiftAPILoading(false);
          });
      }
    }, [isSuccess]);

    // if (isSuccess && !giftAPILoading) {
    if (isSuccess && !giftAPILoading) {
      return (
        <div className="GiftSuccessContent">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <div className="GiftSuccessContentWrapper">
            <h1>Congratulations!</h1>
            <img src={selectLeft ? "NFT1.png" : selectRight ? "NFT2.png" : ""} />
            <p>VOGUE SINGAPORE x 8SIAN LOVE CHAIN NFT {selectLeft ? "DREAM" : selectRight ? "DESIRE" : ""} has been successfully gifted to {recipientName}!</p>
            <div className="CongratulationsFooter">
            <a href="https://twitter.com/8siannft/status/1625793658892189697?s=46&t=hzNxQMUV-9Om-xdAZjayNQ"><button><p>SHARE</p></button></a>
            </div>
          </div>
        </div>
      )
    }

    if (isSuccess && giftAPILoading) {
      return (
        <div className="GiftModalContent">
          <CircularProgress
            sx={{
              color:
                "linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%)",
            }}
          />
          <p>Sending Email</p>
        </div>
      )
    }
    return (
      <div className="GiftFormOverallContent">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
        <div className="GiftFormContent">
          <div className="GiftFormHeader">

            <h1>LOVE CHAIN NFT GIFTING</h1>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              write?.();
            }}
          >
            <div className="GiftFormTop">
              <div className="FormItem">
                <p>RECIPIENT'S WEB3 WALLET</p>
                <input
                  type="text"
                  placeholder="Enter recipient's wallet"
                  value={targetAddress}
                  onChange={handleAddressChange}
                  required
                />
              </div>
              <div className="FormItem">
                <p>SENDER'S NAME</p>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={senderName}
                  onChange={handleSenderNameChange}
                  required
                  maxLength={100}
                />
              </div>
            </div>
            <br className="" />
            <hr />
            <br className="" />
            <div className="GiftFormMiddle">
              <div className="GiftFormMiddleLeft">
                <img
                  src={selectLeft ? "NFT1.png" : selectRight ? "NFT2.png" : ""}
                />
              </div>
              <div className="GiftFormMiddleCenter">
                <p>ITEM</p>
                <h1>
                  8SIAN X VOGUE SINGAPORE LOVE CHAIN NFT (
                  {selectLeft ? "DREAM" : selectRight ? "DESIRE" : ""})
                </h1>
                <p>ID</p>
                <select
                  onChange={handleTokenIDChange}
                  required
                >
                  <option selected="selected">Please Select Your NFT</option>
                  {selectLeft ? (
                    dreamNFTIDs.map((id, i) => (
                      <option key={i} id={id}>
                        {id}
                      </option>
                    ))
                  ) : selectRight ? (
                    desireNFTIDs.map((id, i) => (
                      <option key={i} id={id}>
                        {id}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div className="GiftFormMiddleRight">
                <p>QTY</p>
                <h1>1</h1>
              </div>
            </div>
            <br className="" />
            <hr />
            <br className="" />
            <div className="GiftFormEnd">
              <div className="GiftFormRow">
                <div className="FormItem">
                  <p>RECIPIENT'S NAME</p>
                  <input
                    type="text"
                    placeholder="Enter recipient's name"
                    value={recipientName}
                    onChange={handleRecipientNameChange}
                    required
                    maxLength={100}
                  />
                </div>
                <div className="FormItem" id="EmailInput">
                  <p>RECIPIENT'S E-MAIL</p>
                  <input
                    type="email"
                    placeholder="Enter recipient's email"
                    onChange={handleRecipientEmailChange}
                    value={recipientEmail}
                    required
                  />
                </div>
              </div>
              <div className="FormItem" id="MessageInput">
                <p>MESSAGE</p>
                <input
                  type="text"
                  placeholder="Write your message.. (max 500 character)"
                  onChange={handleGiftMessageChange}
                  value={giftMessage}
                  maxLength={500}
                  required
                />
              </div>
            </div>
            <div className="GiftFormSubmit">
              {isLoading ? (
                <>
                  <CircularProgress
                    sx={{
                      color:
                        "linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%)",
                    }}
                  />
                  <p>Transfer in Progress</p>
                </>
              ) : (
                <button disabled={!write}>GIFT</button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="GiftButton" onClick={() => handleOpen()}>
        GIFT
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style }}
        >

          {pageState === 0 ? <GiftSelector /> : <GiftForm initalTokenID={selectLeft ? dreamNFTIDs[0] : desireNFTIDs[0]} />}
        </Box>
      </Modal>
    </>
  );
}
