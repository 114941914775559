import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "./MintModal.css";
import Web3Mint from "../Web3Mint/Web3Mint";
import Web3LoveListMint from "../Web3Mint/Web3LoveListMint.js";
import LoginModal from "../LoginModal/LoginModal";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 786,
      lg: 1000,
      xl: 1200,
      xxl: 1600,
    },
  },
});


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '100%', sm: '600px', md: '700px', lg: '1000px', xl: '1200px', xxl: "95%" },
  height: { xs: '100%', sm: '600px', md: '800px', lg: '800px', xl: '900px', xxl: "90%" },
  minHeight: "min-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: 'block',
  flexDirection: "column",
  rowGap: "10px",
  overflowY: "scroll",
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal(quantity) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="ConnectButton" onClick={handleOpen}>
        <h1>MINT NOW</h1>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, display: "flex", flexDirection: "column", justifyContent: "center", rowGap: { xs: '1rem', lg: '2rem', xl: '3rem' }}}>
          <Web3Mint quantity={quantity} />
          {/* <Web3LoveListMint quantity={quantity} /> */}
          <Button onClick={handleClose} sx={{fontFamily: "poppins"}}>Return to Previous Menu</Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function MintModal(props) {
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState(0.022);
  const [quantity, setQuantity] = useState(1);

  const handleChange = (event) => {
    setQuantity(event.target.value);
    // setPrice(quantity * 0.02);
  };

  const handleClose = (event, reason) => {
    console.log(event, reason);
    if (reason === "backdropClick") {
      return;
    } else {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setPrice(quantity * 0.022)
  }, [quantity])

  return (
    <ThemeProvider theme={theme}>
      <div className="MintButton" id={props.buttontype} onClick={() => handleOpen()}>
        <h1>{props.buttontype == "navbarMintButton" ? "MINT" : "MINT NOW"}</h1>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="MintModalOverallContainer">
          <div className="MintModalHeader">
            <div className="MintModalHeaderCloseIcon">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="MintModalHeaderText">
              <h1>LOVE CHAIN NFT MINTING & GIFTING</h1>
            </div>
          </div>
          <div className="MintModalContent">
            <div className="MintModalTop">
              <div className="MintModalLeft">
                <img src="MintModal.png" />
              </div>
              <div className="MintModalRight">
                <h1><i>VOGUE</i>&nbsp;SINGAPORE x 8SIAN LOVE CHAIN NFT</h1>
                <div className="MintModalPrice">
                  <img src="eth_logo.png" />
                  <h1>{price}</h1>
                </div>
                <p>
                  8SIAN collaborates with Vogue Singapore to celebrate love in the Metaverse this Valentine's Day through a giftable Love Chain NFT set. Holding a Love Chain NFT provides access to activities, games and a chance to win unique prizes.
                </p>
                <div className="MintModalQuantitySelector">
                  <h1>Quantity</h1>
                  <FormControl variant="standard">
                    <Select
                      value={quantity}
                      label="quantity"
                      onChange={handleChange}
                      sx={{ width: { xs: '100px', sm: '100px', md: '200px', lg: '200px', xl: '200px' }, borderRadius: { xs: '10px', xl: '20px' }, border: "1px solid black", textAlign: "center", fontSize: {xs: "12px", xl: "20px"} }}
                      disableUnderline
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <ChildModal quantity={quantity} />
              </div>
            </div>
            <div className="MintModalBottomDesktop">
              <div className="MintModalBottomImgDesktop">
                <img src="Heartlink.png" />
              </div>
              <div className="MintModalBottomRightDesktop">
                <div className="MintModalBottomRightWrapperDesktop">
                  <h2>PURCHASED A LOVE CHAIN NFT?</h2>
                  <h1>GIFT TO YOUR LOVED ONES NOW!</h1>
                  <LoginModal buttontype="mintmodalLogin" />
                </div>
              </div>
            </div>
            <div className="MintModalBottomMobileBorder">
              <div className="MintModalBottomMobile">
                <div className="MintModalTextMobile">
                  <h2>PURCHASED A LOVE CHAIN NFT?</h2>
                  <h1>GIFT TO YOUR LOVED ONES NOW!</h1>
                </div>
                <img src="Heartlink.png" className="GiftHearLinkImg" />
                <LoginModal buttontype="mintmodalLogin" />
              </div>
            </div>
          </div>
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

// trigger