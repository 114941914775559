import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "./LoginModal.css";
import Web3Login from "../Web3Login/Web3Login";
import { useSelector } from 'react-redux'
import Congratulations from "../Congratulations/Congratulations";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 786,
      lg: 1000,
      xl: 1200,
      xxl: 1600,
    },
  },
});


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '300px', sm: '600px', md: '700px', lg: '750px', xl: '800px'},
  height: { xs: '300px', sm: '600px', md: '700px', lg: '800px', xl: '1000px'},
  minHeight: "min-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: 'block',
  flexDirection: "column",
  rowGap: "10px",
  overflowY: "scroll",
  pt: 2,
  px: 4,
  pb: 3,
};


export default function LoginModal(props) {
  const noWeb3 = props.noWeb3;
  const [open, setOpen] = React.useState(false);
  const LoginState = useSelector((state) => state.loginstate.value);
  const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WEBSITE_ROOT_URL_DEV: process.env.REACT_APP_WEBSITE_ROOT_URL_PROD
  const handleClick = () => {
    if (LoginState) {
      if (window.location.pathname == "/profile") {
        window.location = baseURL
      } else { window.location = baseURL +"/profile" }
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  if(noWeb3) {
    return (
      <ThemeProvider theme={theme}>
        <div className="LoginButton" onClick={handleClick} id={props.buttontype}>
          <h1>{props.buttontype == "loginButton" ? LoginState ? window.location.pathname == "/profile" ? "HOME" : "PROFILE" : "LOGIN" : props.buttontype == "lovemeterLogin" ? "COMPLETE NOW" : props.buttontype == "mintmodalLogin" ? "GIFT" : "COMPLETE QUESTS"}</h1>
        </div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
          <Box
              sx={{
                ...style,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                rowGap: "3rem",
              }}
          >
            <Congratulations title="No web3 wallet found!"
                             subtitle="Please install Metamask extension or use the browser in your Metamask mobile app to log in."
                             imgSrc="lovepile.png"
                             toShare={false}
            />
          </Box>
        </Modal>
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <div className="LoginButton" onClick={handleClick} id={props.buttontype}>
        <h1>{props.buttontype == "loginButton" ? LoginState ? window.location.pathname == "/profile" ? "HOME" : "PROFILE" : "LOGIN" : props.buttontype == "lovemeterLogin" ? "COMPLETE NOW" : props.buttontype == "mintmodalLogin" ? "GIFT" : "COMPLETE QUESTS"}</h1>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: "3rem",
          }}
        >
          <Web3Login />
          <Button onClick={handleClose}>Return to Previous Menu</Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
