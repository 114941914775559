import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { ethers } from "ethers";
import { SiweMessage } from "siwe";
import { useState, useEffect } from "react";
import api from "../API/api";
import "./Web3Mint.css";
import abi from "../../artifacts/EXV_metadata.json";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import Congratulations from "../Congratulations/Congratulations";

export function Web3Mint(quantity) {
  const { address, connector, isConnected } = useAccount();
  const { data: ensAvatar } = useEnsAvatar({ address });
  const { data: ensName } = useEnsName({ address });
  const { connect, connectors, error, pendingConnector } =
    useConnect();
  const { disconnect } = useDisconnect();
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoggedIn, setLoggedInStatus] = useState(false);
  const [NFTOwnership, setNFTOwnership] = useState(false);
  const [checkedNFTOwnership, setCheckedNFTOwnership] = useState(false);
  const [imgURL, setImgURL] = useState("");

  const domain = window.location.host;
  const origin = window.location.origin;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const contract_address = "0xfdDC8E98532d41732D09BbC291bcD0698b21A4Eb";

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedQuantity = useDebounce(quantity.quantity.quantity, 500);
  const etherStringPrice = String(debouncedQuantity * 0.022);

  const { config } = usePrepareContractWrite({
    address: contract_address,
    abi: abi.output.abi,
    functionName: "mint",
    args: [debouncedQuantity],
    overrides: {
      from: address,
      value: ethers.utils.parseEther(etherStringPrice),
    },
    enabled: Boolean(debouncedQuantity),
  });
  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  if (isConnected) {
    console.log(address);
    console.log(quantity.quantity.quantity);
    // mintFreeTokens()

    return (
      <>
        {/* <Congratulations title={"CONGRATULATIONS ON MINTING!"}
          subtitle={"Your mint has contributed 100 points to the Love Meter!"} 
          imgSrc={"lovepile.png"}
          type={1}
          styleType={1}/> */}
        <div className="ConfirmTransactionPage">
          <div className="WalletAddress">
            <h1>Confirm your transaction</h1>
            <p>Connected with: {address.slice(0, 8) + "..."}</p>
          </div>
          <div className="MintModalPrice">
            <img src="eth_logo.png" />
            <h1>{etherStringPrice}</h1>
            <h1>Quantity: {debouncedQuantity}</h1>
          </div>
          {(isLoading && !isSuccess) && (
            <>
              <CircularProgress sx={{ color: "linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%)" }} />
            </>
          )
          }
          {(!isSuccess && !isLoading) && (
            <div className="ConfirmTransaction" onClick={write}>
              Confirm Transaction
            </div>
          )}
          {(isSuccess && !isLoading) && (
            <>
        <Congratulations title={"CONGRATULATIONS ON MINTING!"}
          subtitle={"Your mint has contributed 100 points to the Love Meter!"} 
          imgSrc={"lovepile.png"}
          type={1}
          styleType={1}/>
              {/* <div>
                <a href={`https://etherscan.io/tx/${data?.hash}`}>View on Etherscan</a>
            </div> */}
            </>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="ConnectText">
          <h1>CONNECT YOUR WALLET</h1>
          <p>Select your wallet provider from the list mentioned below.</p>
        </div>
        <div className="ButtonContainer">
          {connectors.map((connector) => (
            <button
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => connect({ connector })}
              className="ConnectButtons"
            >
              <img src={connector.name == "MetaMask" ? "metamasklogo.png" : connector.name == "WalletConnect" ? "walletconnectlogo.png" : connector.name == "Coinbase Wallet" ? "coinbaselogo.png" : ""} />
              {connector.name}
              {!connector.ready && " (unsupported)"}
              {isLoading &&
                connector.id === pendingConnector?.id &&
                " (connecting)"}
            </button>
          ))}

          {error && <div>{error.message}</div>}
        </div>
      </>
    );
  }
}
export default Web3Mint;
