import React from 'react';
import './QuizNumberBox.css';
import ActiveDot from "../logos/ActiveDot";

const QuizNumberBox = ({ isActive, number }) => {
    return (
        <div className={isActive ? 'QuizNumberBox ActiveBox' : 'QuizNumberBox'}>
            <div className="QuizNumber">{number}</div>
            {isActive && <div className="ActiveDot"></div>}
        </div>
    );
};

QuizNumberBox.defaultProps = {
    isActive: true
}

export default QuizNumberBox;
