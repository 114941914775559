import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
    return (
        <div className="LoadingAPIContainer">
            <CircularProgress sx={{ color: "linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%)" }} /><
            /div>
    );
};

export default Loading;
