import './App.css';
import React, { useState, useEffect, Fragment, useCallback } from "react";

import { useConnect } from 'wagmi'
import Web3Login from './components/Web3Login/Web3Login'
import Header from './components/Header/Header'
import Banner from './components/Banner/Banner'
import Title from './components/Title/Title'
import CustomCountdown from './components/Countdown/Countdown';
import Footer from './components/Footer/Footer';
import PartnerLogo from './components/PartnerLogo/PartnerLogo';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import EventDuration from './components/EventDuration/EventDuration';
import { useSelector } from 'react-redux'
import PostLogin from './components/PostLogin/PostLogin';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AppContextProvider } from "./contexts/AppContext";
import Quiz from "./components/Quiz/Quiz";
import Congratulations from './components/Congratulations/Congratulations';
// import ErrorPage from "./error-page";

export function App() {
  const LoginState = useSelector((state) => state.loginstate.value);


  function SetRouting(props) {
    console.log("appstate", props.loggedin)
    if (props.loggedin) {
      return (
        <>
          <div>
            <PostLogin />
          </div>
        </>
      )
    }
    else {
      return (
        <>
          <Banner />
          <CustomCountdown />
        </>
      )
    }
  }
  function MainPage() {
    return (
      <>
        <Banner />
        <CustomCountdown />
      </>
    )
  }
  function LoginPage(props) {
    console.log("appstate", props.loggedin)
    if (props.loggedin) {
      return (
        <>
          <div>
            <PostLogin />
          </div>
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage />,
    },
    {
      path: "/profile",
      element: <LoginPage loggedin={LoginState} />,
    },
    {
      path: "/quest",
      element: <Quiz loggedin={LoginState} />,
    },
    {
      path: "/spatiallanding",
      element: <Congratulations title={"CONGRATULATIONS ON COMPLETION OF THE TREASURE HUNT!"}
        subtitle={"Your treasure hunt has contributed 50 points to the Love Meter!"}
        imgSrc={"lovepile.png"}
        type={3}
        styleType={2} />,
    },
    ]);
  return (
    <div className='App'>
      <AppContextProvider>
        <Header />
        {/* <SetRouting loggedin={LoginState}/> */}
        <RouterProvider router={router} />
        <Footer />
      </AppContextProvider>
    </div>
  )
}
export default App;
