import { configureStore } from '@reduxjs/toolkit'
import countdownReducer from '../components/Countdown/CountdownSlice.js'
import loginReducer from '../components/Web3Login/LoginSlice.js'
import profileReducer from '../components/Profile/ProfileSlice.js'

export default configureStore({
  reducer: {
    countdown: countdownReducer,
    loginstate: loginReducer,
    profile: profileReducer,
  },
})