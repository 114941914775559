import axios from "axios";
import { useEffect } from "react"
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useSelector, useDispatch } from 'react-redux'
import ProfileSlice, { access } from '../Profile/ProfileSlice'
import protectedAxiosRequest from "../../utils/axiosHelper";
// import { useSelector, useDispatch } from 'react-redux'

// const baseURL = 'http://localhost:8100/api'
const baseURL = 'https://ilodialabs-apiserver.herokuapp.com/api'
// const baseURL = 'http://0.0.0.0:5123/api'

// const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_APIROOT_DEV : process.env.REACT_APP_APIROOT

//functions to make api calls

const api = {
  verify: (body) => {
    return axios.post(`${baseURL}/eightsianLogin/`, body);
  },
  prereg: (body) => {
    return axios.post(`${baseURL}/preregistration/`, body);
  },
  profile: () => {
    return protectedAxiosRequest.get(`${baseURL}/eightsianProfile/`);
  },
  dailyQuest: () => {
    return protectedAxiosRequest.get(`${baseURL}/dailyQuest/`);
  },
  completeQuest: (body) => {
    return protectedAxiosRequest.post(`${baseURL}/eightsianCompleteQuest/`, body);
  },
  completeSpatial: () => {
    return protectedAxiosRequest.post(`${baseURL}/eightsianCompleteSpatial/`);
  },
  gift: (body) => {
    return axios.post(`${baseURL}/eightsianGift/`, body);
  },
  update: (body) => {
    return axios.post(`${baseURL}/token/refresh/`, body)
  },
  updateuser: ({uuid, body}) => {
    return protectedAxiosRequest.put(`${baseURL}/userUpdate/${uuid}/`, body)
  },
};



export default api;
