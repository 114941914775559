import React from 'react';
import './BannerWithSubtitle.css';

const BannerWithSubtitle = ({ subtitle, title }) => {
    return (
        <div className="BannerWithSubtle">
            <p className="BannerSubtitle">
                {subtitle}
            </p>
            <p className="BannerTitle">
                {title}
            </p>
        </div>
    );
};

BannerWithSubtitle.defaultProps = {
    subtitle: 'QUEST OF THE DAY'
}

export default BannerWithSubtitle;
