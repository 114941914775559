import "./Footer.css";

export function Footer() {
  return (
    <>
    <div className="FooterContainer">
      <div className="Socials">
      <div className="LeftBorder"><hr/></div>
      <div className="IconContainer">
        <a target="_blank" rel="noopener noreferrer" href="https://discord.com/invite/8sian"><img className="sociallogo" src="discord.svg"/></a>
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/8siannft"><img className="sociallogo" src="twitter.svg"/></a>
        <a target="_blank" rel="noopener noreferrer" href="https://8sian.io/"> <img className="eightsianlogo" src="8sian-small-logo-black.jpg"/></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/8siannft/"><img className="sociallogo" src="instagram.svg"/></a>
        <a target="_blank" rel="noopener noreferrer" href="https://opensea.io/collection/8sian-main-collection"><img className="sociallogo" src="opensea.svg"/></a>
      </div>
      <div className="RightBorder"><hr/></div>
      </div>
      <p className="copyright">© Copyright 2023 8SIAN</p>
    </div>

    </>
  );
}
export default Footer;
