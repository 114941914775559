import MintModal from "../MintModal/MintModal";
import "./LoveChain.css";
import { useState, useEffect } from "react";

export function LoveChain() {
  const [openParent, setOpenParent] = useState(false);

  const handleOpen = () => {
    setOpenParent(!openParent);
  };

  function handleParentClose() {
    setOpenParent((openParent) => !openParent);
  }

  function handleKeepOpen() {
    setOpenParent((openParent) => !openParent);
  }

  return (
    <div className="LoveChainContainer">
      <h1>LOVE CHAIN NFT</h1>
      <div className="LoveChainTop">
        <div className="LoveChainTopLeft">
          <img src="guyavatar.png" />
        </div>
        <div className="LoveChainTopCenter">
          <div className="TokenImageContainer">
            <img src="NFT1.png" />
            <h1>Get it and Gift it!</h1>
            <img src="NFT2.png" />
          </div>
          <h1>Get it and Gift it!</h1>
        </div>
        <div className="LoveChainTopRight">
          <img src="girlavatar.png" />
        </div>
      </div>
      <div className="LoveChainUtilContainer">
        <h1>
          As a holder of the Love Chain NFT, you are entitled to the following
          utilities:
        </h1>
        <ul>
          <li>Receive and Gift Love Chain NFTs</li>
          <li>Access to Community Campaigns</li>
          <li>Win various unique prizes</li>
        </ul>
        <MintModal buttontype="LovechainMintButton"/>
      </div>
    </div>
  );
}
export default LoveChain;
