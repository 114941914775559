import React from 'react';
import './CommonButton.css'

const CommonButton = ({ btnText, btnId, onClick, disabled }) => {
    return (
        <button
            className="CommonButton"
            onClick={onClick}
            id={btnId}
            disabled={disabled}
        >
            {btnText}
        </button>
    );
};

export default CommonButton;
