import React from 'react'
import "./Congratulations.css"
import api from "../API/api";

function Congratulations({ title, subtitle, imgSrc, toShare, type, styleType }) {
    const tweet1URL = "https://twitter.com/8siannft/status/1625793658892189697?s=46&t=hzNxQMUV-9Om-xdAZjayNQ"
    const tweet2URL = "https://twitter.com/8siannft/status/1625793912685359105?s=46&t=hzNxQMUV-9Om-xdAZjayNQ"
    const tweet3URL = "https://twitter.com/8siannft/status/1626804619237298176?s=61&t=lj6A5pw199_botg5iWLKmA"
    const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WEBSITE_ROOT_URL_DEV : process.env.REACT_APP_WEBSITE_ROOT_URL_PROD

    if(window.location==baseURL+"/spatiallanding") {
        api
        .completeSpatial()
        .then((response) => {
            console.log(response)
        })
        .catch((error) => {
            if (error) {
                console.log(error);
            }
        })
        .finally(() => {
        });
    }
    return (
        <div className="CongratulationsContainer" style={{minHeight: styleType==2? "85vh": "0"}}>
            <div className="CongratulationsHeader">
                <h1>{title}</h1>
            </div>
            <div className="CongratulationsBody">
                <img src={imgSrc}/>
                <p>{subtitle}</p>
            </div>
            <div className="CongratulationsFooter">
                {
                    toShare && <a href={type==1?tweet1URL:type==2?tweet2URL:tweet3URL} target="_blank" rel="noopener noreferrer"><button><p>{type==3?"TAG A FRIEND TO COMPLETE":"RETWEET TO COMPLETE"}</p></button></a>
                }
            </div>
        </div>
    )
}

Congratulations.defaultProps = {
    toShare: true,
}

export default Congratulations
