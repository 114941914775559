import { createSlice } from '@reduxjs/toolkit'

export const LoginSlice = createSlice({
  name: 'LoginState',
  initialState: {
    value: localStorage.getItem('siweLoggedIn') || false,
  },
  reducers: {
    login: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = true
    },
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      window.localStorage.removeItem('siweLoggedIn');
      state.value = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { login } = LoginSlice.actions
export const { logout } = LoginSlice.actions

export default LoginSlice.reducer