import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from "wagmi";
import { ethers } from "ethers";
import { SiweMessage } from "siwe";
import { useState } from "react";
import api from "../API/api";
import "./Web3Login.css";
import "../Web3Mint/Web3Mint.css";
import { useDispatch } from 'react-redux'
import { access } from '../Profile/ProfileSlice'
import { login, logout } from './LoginSlice'
import CircularProgress from '@mui/material/CircularProgress';
import jwt_decode from "jwt-decode";


export function Web3Login() {
  const { address, connector, isConnected } = useAccount();
  const { data: ensAvatar } = useEnsAvatar({ address });
  const { data: ensName } = useEnsName({ address });
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { disconnect } = useDisconnect();
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoggedIn, setLoggedInStatus] = useState(false);
  const [NFTOwnership, setNFTOwnership] = useState(false);
  const [checkedNFTOwnership, setCheckedNFTOwnership] = useState(false);
  const [signature, setSignature] = useState()
  const [signerErrorCode, setSignerErrorCode] = useState("")
  const [signerErrorMsg, setSignerErrorMsg] = useState("")
  const [apiErrorCode, setAPIErrorCode] = useState("")
  const [apiErrorMsg, setAPIErrorMsg] = useState("")

  const domain = window.location.host;
  const origin = window.location.origin;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const dispatch = useDispatch()

  const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WEBSITE_ROOT_URL_DEV : process.env.REACT_APP_WEBSITE_ROOT_URL_PROD

  function openOnSuccessfulLogin() {
    // console.log(profileURL)
    window.location = baseURL + "/profile"
  }

  function createSiweMessage(address, statement) {
    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId: "1",
    });
    return message.prepareMessage();
  }

  async function signInWithEthereum() {
    const message = createSiweMessage(
      await signer.getAddress(),
      "Sign in with Ethereum to the app."
    );
    try {
      const signature = await signer.signMessage(message);
      setSignature(signature)
      setIsLoading2(true)
      api
      .verify(JSON.stringify({ message, signature, address }))
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          localStorage.setItem("siweLoggedIn", true);
          localStorage.setItem("authTokens", JSON.stringify(response.data));
          dispatch(access(jwt_decode(response.data.access)));
          setCheckedNFTOwnership(true);
          setIsLoading2(false)
          setLoggedInStatus(true);
          dispatch(login());
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.response) {
          setAPIErrorCode(error.response.status);
          setAPIErrorMsg(error.response.data)
        }
        setLoggedInStatus(false);
        setNFTOwnership(false);
        setCheckedNFTOwnership(true);
        setIsLoading2(false)
      });
    } catch (error) {
      setSignerErrorCode(error.code)
      setSignerErrorMsg(error.message)
    }
  }

  if(signerErrorCode) {
    return (
      <div className="ErrorContainer">
      <h1>Signature Request Rejected</h1>
      <p>Please refresh the page to login</p>
    </div>
    )
  }

  if (isLoading2) {
    return (
      <div className="LoadingContainer">
        <CircularProgress />
        <h3>Logging in...</h3>
      </div>
    )
  }

  if (isLoggedIn && checkedNFTOwnership) {
    openOnSuccessfulLogin()
    return (
      <div className="LoadingContainer">
        <CircularProgress />
        <h3>Logging in...</h3>
      </div>
    );
  }

  else if (isConnected && !checkedNFTOwnership) {
    signInWithEthereum()
    return (
      <div className="LoadingContainer">
        <CircularProgress />
        <h3>Waiting for signature...</h3>
      </div>
    );
  }

  else if (isConnected && checkedNFTOwnership && !NFTOwnership) {
    if (apiErrorCode) {
      return (
        <div className="ErrorContainer">
          <h1>{apiErrorCode} {apiErrorMsg}</h1>
          <p>Are you using the correct wallet?</p>
        </div>
      )
    }
    return (
      <div className="ErrorContainer">
        <h1 color="black">Error. Please Refresh and Try Again!</h1>
        <h1>{apiErrorCode} {apiErrorMsg}</h1>
      </div>
    );
  }

  else {
    return (
      <>
        <div className="ConnectText">
          <h1>CONNECT YOUR WALLET</h1>
          <p>Select your wallet provider from the list mentioned below.</p>
        </div>
        <div className="ButtonContainer">
          {connectors.map((connector) => (
            <button
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => connect({ connector })}
              className="ConnectButtons"
            >
              <img src={connector.name == "MetaMask" ? "metamasklogo.png" : connector.name == "WalletConnect" ? "walletconnectlogo.png" : connector.name == "Coinbase Wallet" ? "coinbaselogo.png" : ""} />
              {connector.name}
              {!connector.ready && " (unsupported)"}
              {isLoading &&
                connector.id === pendingConnector?.id &&
                " (connecting)"}
            </button>
          ))}

          {error && <div>{error.message}</div>}
        </div>
      </>
    );
  }
}
export default Web3Login;
