import axios from 'axios';
const protectedAxiosRequest = axios.create();
const baseURL = 'https://ilodialabs-apiserver.herokuapp.com/api';

const protectedReqConfigMiddleware = (config) => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens"));
    const access = authTokens?.access;
    if (access) {
        const argHeaders = config?.headers || {};
        config.headers = {
            Authorization: `Bearer ${access}`,
            ...argHeaders
        };
        return config;
    }
    // Don't run the request if the user token is not available
    return Promise.reject('No Token');
};

const protectedReqErrorMiddleware = (error) => {
    return Promise.reject(error);
};

const protectedResMiddleware = (response) => response;

export const getTokensService = async (payload) => {
    try {
        const path = `${baseURL}/token/refresh/`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };
        const res = await fetch(path, options);
        const data = await res.json();

        if (data.error) {
            throw new Error(data.info || data.error);
        }

        if (data?.status === 'error') {
            throw new Error(data.message || 'Something went wrong');
        }

        return { data, error: '' };
    } catch (e) {
        return { error: e.message };
    }
};
const protectedResErrorMiddleware = async (resError) => {
    try {
        const status = resError?.response?.status;
        const originalRequest = resError.config;
        let retry = originalRequest?.retry;

        if (!retry) {
            retry = 3;
        }
        if (retry === 0) {
            // Redirect to login
            return Promise.reject(`Max Retry Reached`);
        }

        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const refreshToken = authTokens?.refresh;

        if (refreshToken && status === 401) {
            const payload = { refresh: refreshToken };
            const { data, error } = await getTokensService(payload);
            if (error) {
                console.error('Trying to refresh token', error);
                return Promise.reject(error);
            }

            const accessToken = data?.access;

            if (!accessToken) throw new Error('No Access Token Found.');
            localStorage.setItem("authTokens", JSON.stringify(data));

            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            retry -= 1;
            return protectedAxiosRequest({ ...originalRequest, retry });
        }
        return Promise.reject(resError);
    } catch (error) {
        return Promise.reject(error);
    }
};

// Request interceptors
protectedAxiosRequest.interceptors.request.use(
    protectedReqConfigMiddleware,
    protectedReqErrorMiddleware
);

// Response interceptors
protectedAxiosRequest.interceptors.response.use(
    protectedResMiddleware,
    protectedResErrorMiddleware
);

export default protectedAxiosRequest;
