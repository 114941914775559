import { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import api from "../API/api";
import { useSelector, useDispatch } from 'react-redux'

const ProfileForm = ({ titleText, dialogText, labelText, keyValue }) => {
    const { isProfileFormOpen, setProfileFormOpen, userUUID, setName, setDiscord, setTwitter } = useAppContext();
    const [updateContent, setUpdateContent] = useState("")
    const [APILoading, setAPILoading] = useState(false);
    const authTokens = useSelector((state) => state.profile.access);
    const config = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${authTokens?.access}`
        }
    }
    const handleProfileFormClose = () => {
        setProfileFormOpen(!isProfileFormOpen)
        setUpdateContent("")
    }
    const payload = {}
    console.log(keyValue)
    payload[keyValue] = updateContent
    const handleUpdate = () => {
        setAPILoading(true);
        api
            .updateuser({uuid: userUUID, body: payload})
            .then((response) => {
                if (response.status === 200) {
                    if(keyValue=="name") {
                        setName(updateContent)
                    } else if (keyValue=="discord_account") {
                        setDiscord(updateContent)
                    } else if (keyValue=="twitter_account") {
                        setTwitter(updateContent)
                    }
                }
            })
            .catch((error) => {
            })
            .finally(() => {
                setAPILoading(false);
                handleProfileFormClose()
            });
    }

    return (
        <Dialog open={isProfileFormOpen} onClose={setProfileFormOpen}>
            <DialogTitle>Update your {titleText}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter your new {dialogText}:
                </DialogContentText>
                <TextField
                    margin="dense"
                    label={labelText}
                    type="text"
                    value={updateContent}
                    onChange={(event) => setUpdateContent(event.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleProfileFormClose}>Cancel</Button>
                <Button onClick={handleUpdate}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProfileForm