import "./ScrollToTop.css"

export function ScrollToTop() {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    return (
        <div className="ScrollToTopContainer">
        <div className="ScrollToTopButton" onClick={goToTop}>
            Back To Top
        </div>
        </div>
    )
}
export default ScrollToTop;